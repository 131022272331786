import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Subscription } from '../../_models/service';
import { SubscriptionService } from '../../_services/subscription.service';

@Component({
  selector: 'app-slot-table',
  templateUrl: './slot-table.component.html',
  styleUrls: ['./slot-table.component.scss']
})
export class SlotTableComponent {

    _subscription: Subscription;

    @Input() set subscription (s: Subscription) {
        this._subscription = s;
    }
    @Input() showDelete: boolean = true;

    @Output() unlinked = new EventEmitter<number>();

    constructor(
        private subscriptionService: SubscriptionService,
    ) {
    }

    unlinkSlot(slotId: number) {
        if (confirm("Are you sure you want to unlink this " + this._subscription.getSlotName() + "?")) {
            this.subscriptionService.unlinkSlot(this._subscription.id, slotId).subscribe(
                res => {
                    this._subscription = new Subscription().deserialize(res);
                    this.unlinked.emit(slotId);
                },
                err => {
                    console.log(err);
                }
            );
        }
    }


    cancelSlot(slotId: number) {
        if (confirm("Are you sure you want to delete this " + this._subscription.getSlotName() + "? This action will unlink and remove the " + this._subscription.getSlotName() + " from your subscription immediately. The price for the subscription will remain the same until after the subscriptions renewal date. Please note! This action will not cancel the subscription and can not be undone.")) {
            this.subscriptionService.cancelSlot(this._subscription.id, slotId).subscribe(
                res => {
                    this._subscription = new Subscription().deserialize(res);
                },
                err => {
                    console.log(err);
                }
            );
        }
    }

}
