<table class="min-w-full divide-y divide-gray-300">
    <thead>
        <tr>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{ 'subscription.Service' | translate }}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{ 'subscription.Status' | translate }}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{ 'subscription.Qty' | translate }}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{ 'subscription.Billed' | translate }}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{ 'subscription.Renews' | translate }}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{ 'subscription.Price-p-month' | translate }}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">&nbsp;</th>
        </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
        <tr *ngFor="let sub of subscriptions">
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span>{{ sub.getServiceName() }}</span>
                <span *ngIf="loggedInAccountUser && (loggedInAccountUser.isAdmin() || loggedInAccountUser.isManager())">&nbsp; {{ sub.getStripeSubscriptionId() }}</span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                <span *ngIf="sub.getStripeStatus() == 'incomplete'">
                    <a class="text-red-600 flex items-center gap-1" [routerLink]="['/service/subscription/' + sub.getId()]">
                        Incomplete
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                    </a>
                </span>
                <span *ngIf="sub.getStripeStatus() == 'incomplete_expired'">
                    <a class="text-red-600 flex items-center gap-1" [routerLink]="['/service/subscription/' + sub.getId()]">
                        Expired
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                    </a>
                </span>
                <span *ngIf="sub.getStripeStatus() == 'trialing'">
                    <a class="text-blue-600" [routerLink]="['/service/subscription/' + sub.getId()]">
                        Trial
                    </a>&nbsp;to&nbsp;
                    <span *ngIf="sub.getStripeStatus() == 'trialing'" class="link">{{ sub.getTrialEnd() | date:'mediumDate' }}</span>

                </span>
                <span *ngIf="sub.getStripeStatus() == 'active'">
                    <a class="text-green-600" [routerLink]="['/service/subscription/' + sub.getId()]" *ngIf="sub.getCreatedBy() == loggedInAccountUser?.getId()">
                        Active
                    </a>
                    <div class="text-green-600" *ngIf="sub.getCreatedBy() != loggedInAccountUser?.getId()">
                        Active
                    </div>
                </span>
                <span *ngIf="sub.getStripeStatus() == 'past_due'">
                    <a class="text-red-600 flex items-center gap-1" [routerLink]="['/service/subscription/' + sub.getId()]">
                        Past due
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                    </a>
                </span>
                <span *ngIf="sub.getStripeStatus() == 'canceled'" class="text-gray-600">
                    Canceled
                </span>
                <span *ngIf="sub.getStripeStatus() == 'unpaid'" class="">
                    <a class="text-red-600 flex items-center gap-1" [routerLink]="['/service/subscription/' + sub.getId()]">
                        Unpaid
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                    </a>
                </span>

            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex gap-2">
                {{ sub.getQuantity() }}
                <span *ngIf="sub.slot_count >= 1 && sub.getCreatedBy() == loggedInAccountUser?.getId()">
                    <a [routerLink]="['/service/subscription/' + sub.getId()]">
                        <i class="bi bi-pencil"></i>
                    </a>
                </span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ sub.getPeriod() | titlecase }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span class="font-semibold" *ngIf="sub.getCancelsAt() && sub.getStripeStatus() != 'canceled'">{{ 'subscription.Ends' | translate }}:&nbsp;</span>
                <span *ngIf="sub.getCancelsAt() && sub.getStripeStatus() != 'canceled'">{{ sub.getCancelsAt() | date:'mediumDate' }}</span>
                <span *ngIf="!sub.getCancelsAt() && sub.isActive()">{{ sub.getRenewsAt() | date:'mediumDate' }}</span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ sub.getPricePerMonth() | number }} {{ sub.getCurrency() }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500" align="right">
                <!--<button *ngIf="activeSubscription && sub.getId() == activeSubscription.getId() && userCount < activeSubscription.getQuantity()" class="btn btn-sm btn-light" (click)="downgradeUserCount()">Downgrade</button>-->
                <!--<button *ngIf="(sub.getStripeStatus() == 'active' || sub.getStripeStatus() == 'trialing') && isCompanyUser()" class="btn btn-sm btn-light" (click)="openSubscriptionModal()">{{ 'subscription.Modify' | translate }}</button>-->
                <button *ngIf="sub.isActive() && !sub.getCancelsAt()" class="btn-outline-blue-sm" (click)="cancelConfirm(sub.getId())">Cancel</button>
                <button *ngIf="sub.isActive() && sub.getCancelsAt()" class="btn-outline-dark-sm">Canceled</button>
            </td>
        </tr>
        <tr *ngIf="!subscriptions || (subscriptions && subscriptions.length == 0)">
            <td colspan="7">
                <div class="text-xs text-gray-500 p-3">
                    No subscriptions found
                </div>
            </td>
        </tr>
    </tbody>
</table>
