import { Component, OnInit, Input } from '@angular/core';

import { AccountUser } from '../../_models/user';
import { Subscription } from '../../_models/service';

import { AccountService } from '../../_services/account.service'
import { AlertService } from '../../_services/alert.service';
import { SubscriptionService } from '../../_services/subscription.service';

@Component({
  selector: 'app-subscription-table',
  templateUrl: './subscription-table.component.html',
  styleUrls: ['./subscription-table.component.scss']
})
export class SubscriptionTableComponent implements OnInit {

    _companyId: number;
    _accountUserId: number;

    @Input() set companyId(id: number) {
        this._companyId = id;
        this.getSubscriptions();
    }

    @Input() set accountUserId(id: number) {
        this._accountUserId = id;
        this.getSubscriptions();
    }

    subscriptions: Subscription[];

    loggedInAccountUser: AccountUser | undefined | null;

    loading: boolean = false;

    constructor(
        private accountService: AccountService,
        private alertService: AlertService,
        private subscriptionService: SubscriptionService,
    ) { }

    ngOnInit(): void {
        this.loggedInAccountUser = this.accountService.loggedInAccountUserSig();
    }

    getSubscriptions() {
        this.loading = true;

        this.subscriptionService.getSubscriptions(this._companyId, this._accountUserId).subscribe(
            subs => {
                this.subscriptions = subs;
                this.loading = false;
            }
        );
    }

    cancelConfirm(id: number) {
        if (confirm('Are you sure you want to cancel the service and lose all features?')) {
            this.subscriptionService.cancelSubscription(id).subscribe(
                cancel => {
                    this.getSubscriptions();
                    this.alertService.info("The service has been canceled");
                },
                error => {
                    this.alertService.error("You do not have permission to downgrading your account. Please ask an admin user to downgrade the account.");
                }
            );
        }
    }

    /*isCompanyUser() {
        if (this.companyId && this.loggedInAccountUser &&
            (
                this.loggedInAccountUser.isCompanyAdminFor(this.companyId) ||
                this.loggedInAccountUser.isCompanyManagerFor(this.companyId) ||
                this.loggedInAccountUser.isCompanyPartnerFor(this.companyId)
            )
           ) {
            return true;
        }
        return false;
    }*/

}
