<div>

    <div>
        <div *ngIf="showHeadline">
            <div class="mx-auto max-w-4xl text-center">
                <h2 class="text-base font-semibold leading-7 text-blue-600">{{ 'employers.pricing' | translate }}</h2>
                <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{{ 'employers.choose-your-plan' | translate }}</p>
            </div>
            <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            {{ 'employers.choose-your-plan-text' | translate }}
            </p>
        </div>
        <!--
        <div class="mt-16 flex justify-center">
            <fieldset class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
                <legend class="sr-only">Payment frequency</legend>
                <label class="cursor-pointer rounded-full px-2.5 py-1">
                    <input type="radio" name="frequency" value="monthly" class="sr-only">
                    <span>Monthly</span>
                </label>
                <label class="cursor-pointer rounded-full px-2.5 py-1">
                    <input type="radio" name="frequency" value="annually" class="sr-only">
                    <span>Annually</span>
                </label>
            </fieldset>
        </div>
        -->
        <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl sm:grid-cols-2 lg:grid-cols-{{ servicesCount }} lg:max-w-4xl xl:mx-0 xl:max-w-none">

            <div *ngFor="let service of services" class="rounded-3xl p-8 ring-1 ring-gray-200">
                <h3 id="tier-hobby" class="text-lg font-semibold leading-8 text-gray-900">{{ service.getName() }}</h3>
                <p class="mt-4 text-sm leading-6 text-gray-600">
                    {{ service.getIntro(getLanguage()) }}
                </p>
                <div class="mt-6">
                    <div class="text-xl font-bold tracking-tight text-gray-900">
                        <span *ngIf="service.getPrice() == 0" class="text-xl font-bold tracking-tight text-gray-900">
                            {{ 'employers.plans.Free' | translate }}
                        </span>
                        <span *ngIf="service.getPrice() > 0" class="text-xl font-bold tracking-tight text-gray-900">
                            <span *ngIf="service.getCurrencySymbol() != 'kr'">{{ service.getCurrencySymbol() }}</span>{{ service.getMinPrice() | number }}
                            <span *ngIf="service.getCurrencySymbol() == 'kr'">{{ service.getCurrencySymbol() }}</span>
                        </span>
                        <span *ngIf="service.getPrice() > 0" class="text-sm font-semibold leading-6 text-gray-600">
                            <span *ngIf="service.getPer() == 'user'"> {{ 'per' | translate }} {{ 'employers.plans.user' | translate }}</span>
                            <span *ngIf="service.getUseSlots() && service.getSlotName()"> {{ 'per' | translate }} {{ service.getSlotName() || "slot" }}</span>
                            <span *ngIf="service.getPeriod() == 'monthly'"> / {{ 'employers.plans.month' | translate }}</span>
                            <span *ngIf="service.getPeriod() == 'annually'"> / {{ 'employers.plans.year' | translate }}</span>
                            <!--
                            <span *ngIf="service.getUseSlots() && service.getMinSlots() > 1">&nbsp;{{ 'for' | translate }} {{ service.getMinSlots() }} {{ getTranslatedSlotName(service) }}</span>
                            -->
                        </span>
                    </div>
                    <div *ngIf="service.getPrice() > 0 && service.getPeriod() == 'monthly' && service.getIterations() == 1" class="text-sm leading-6 text-gray-600">{{ 'employers.plans.Cancel anytime' | translate }}</div>
                    <div class="flex gap-3">
                        <span *ngIf="service.getUseSlots() && service.getMinSlots() > 1" class="text-sm leading-6 text-gray-600">{{ service.getMinSlots() }} {{ getTranslatedSlotName(service) }}</span>
                        <span *ngIf="service.getPrice() > 0 && service.getPeriod() == 'monthly' && service.getIterations() > 1" class="text-sm leading-6 text-gray-600">{{ service.getIterations() }} {{ 'month term' | translate }}</span>
                    </div>
                </div>
                <!--
                <a href="/add-company" aria-describedby="tier-hobby" class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300">
                    {{ 'employers.plans.get-started-free' | translate }}
                </a>
                -->
                <button *ngIf="service.getPrice() == 0 && service.getPer() == 'company'" (click)="goToAddCompany()" class="mt-6 w-full block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300">
                    {{ 'employers.plans.get-started' | translate }}
                </button>
                <button *ngIf="service.getPrice() > 0" (click)="openServiceModal(service)" class="mt-6 w-full block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300">
                    <span *ngIf="service.getTrialPeriodDays() > 0">
                        {{ 'employers.plans.Try' | translate }}
                        {{ service.getTrialPeriodDays() }}
                        {{ 'employers.plans.days free' | translate }}
                    </span>
                    <span *ngIf="service.getTrialPeriodDays() == 0">
                        {{ 'employers.plans.get-started' | translate }}
                    </span>
                </button>
                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                    <li *ngFor="let inc of service.getIncluded()" class="flex justify-between">
                        <div class="flex gap-x-3">
                            <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                            </svg>
                            {{ service.getIncludedText(inc, getElementLanguage(inc)) }}
                        </div>

                        <div *ngIf="service.getIncludedInfoText(inc, getElementLanguage(inc))">
                            <div class="group relative inline-block">
                                <i class="bi bi-info-circle"></i>
                                <div class="z-10 absolute hidden group-hover:block text-xs text-white bg-gray-600 p-2 rounded-lg mt-1" [ngClass]="service.getIncludedInfoText(inc, getElementLanguage(inc)).length < 15 ? 'w-max' : 'w-52'">
                                    <p class="text-sm">
                                    {{ service.getIncludedInfoText(inc, getElementLanguage(inc)) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>



<app-modal #serviceModal [size]="'xl'">
    <app-service *ngIf="selectedService && serviceModal.isOpen()" [slug]="selectedService.getSlug()"></app-service>
</app-modal>
