import { Component, OnInit, Input, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Service } from '../../_models/service';

import { SubscriptionService } from '../../_services/subscription.service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {

    @ViewChild('serviceModal') serviceModal: any;

    @Input() set setTier(tier: string) {
        this.tier = tier;
        this.subscriptionService.getServices(tier).subscribe(
            services => {
                this.services = services;
                this.servicesCount = services.length;
            },
            error => {
                console.log(error);
            }
        );
    }

    @Input() showHeadline: boolean = true;

    tier: string;

    services: Service[];
    servicesCount: number = 4;

    selectedService: Service;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private subscriptionService: SubscriptionService,
        private translate: TranslateService,
        private router: Router,
    ) { }

    ngOnInit() {
    }

    getTranslatedSlotName(service: Service) {
            if (service && service.getSlotName()) {
                const slotName = service.getSlotName();
                return this.translate.instant(slotName);
            } else {
                return this.translate.instant('slots');
            }
    }

    openServiceModal(service: Service) {
        this.selectedService = service;
        this.serviceModal.open();
    }

    goToAddCompany() {
        this.router.navigate(['/add-company']);
        window.scroll(0, 0);
    }

    /*getIncludedText(included: any) {
        if (included.en)
            return included.en;
        
        else if (included.sv)
            return included.sv;

        else if (included.name)
            return included.name;
    }*/

    getLanguage() {

        if (isPlatformBrowser(this.platformId)) {
            let localStorageLang = localStorage.getItem('currentLang');
            const browserLang = this.translate.getBrowserLang();


            if (localStorageLang) {
                return localStorageLang;

            } else if (browserLang) {
                return browserLang;
            
            } else {
                return 'en';
            }
        } else {
            return 'en';
        }
    }

    getElementLanguage(element: any) {
        if (!element)
            return undefined;

        if (isPlatformBrowser(this.platformId)) {
            let localStorageLang = localStorage.getItem('currentLang');
            const browserLang = this.translate.getBrowserLang();

            if (localStorageLang && element.hasOwnProperty(localStorageLang)) {
                return localStorageLang;

            } else if (browserLang && element.hasOwnProperty(browserLang)) {
                return browserLang;
            
            } else if (element.en) {
                return 'en';

            } else {
                return undefined;
            }
        } else {
            return 'en';
        }
    }

}
