<div>
    <h2>
        <button type="button" 
            (click)="toggleExpanded()"
            [ngClass]="expanded ? 'rounded-t-xl bg-gray-100 border-b-0' : 'rounded-xl bg-white mb-1'"
            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-1 focus:ring-gray-200 hover:bg-gray-100 gap-3">
            <ng-container *ngIf="service">
                <span *ngIf="hasToBuy()">Buy a {{ service.getName() }} slot</span>
                <span *ngIf="hasToLink()">Link this job to a {{ service.getName() }} slot</span>
                <span *ngIf="job.hasLinkedSlot()">This job is linked to a {{ service.getName() }} slot</span>
            </ng-container>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
        </button>
    </h2>
    <div [ngClass]="expanded ? 'block' : 'hidden'" class="border border-gray-200 rounded-b-xl">
        <div class="p-5">

            <dl class="grid grid-cols-2 gap-x-8 gap-y-12 sm:grid-cols-3 mb-4" *ngIf="service && subscription">
                <div class="flex flex-col-reverse border-l border-white/20">
                    <dt class="text-sm leading-7 text-gray-600">Total {{ service.getSlotName() || "slot" }}s</dt>
                    <dd class="text-lg font-semibold tracking-tight">{{ subscription.getSlotCount() }}</dd>
                </div>
                <div class="flex flex-col-reverse border-l border-white/20">
                    <dt class="text-sm leading-7 text-gray-600">Used {{ service.getSlotName() || "slot" }}s</dt>
                    <dd class="text-lg font-semibold tracking-tight">{{ subscription.getSlotUsedCount() }}</dd>
                </div>

                <div class="flex flex-col-reverse border-l border-white/20">
                    <dt class="text-sm leading-7 text-gray-600">Available {{ service.getSlotName() || "slot" }}s</dt>
                    <dd class="text-lg font-semibold tracking-tight">{{ subscription.getSlotAvailableCount() }}</dd>

                </div>
            </dl>

            <button *ngIf="!loading && (subscription && subscription.getSlotAvailableCount() == 0 && job && !job.hasLinkedSlot()) || (job && !subscription)"
                    (click)="serviceModal.open()" class="btn-blue">Buy more slots</button>

            <button (click)="linkSlot(subscription)" *ngIf="!loading && subscription && subscription.getSlotAvailableCount() > 0 && job && !job.hasLinkedSlot()" class="btn-blue">
                Link job
            </button>

            <!--
                <button *ngIf="!loading && (subscription && subscription.getSlotAvailableCount() == 0 && job && !job.hasLinkedSlot()) || (job && !subscription)" [routerLink]="['/company/' + job.getCompanyId() ]" [queryParams]="{ pp: 'pool', tab: 'upgrade' }" class="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Upgrade now
                </button>
            -->

            <button *ngIf="!loading && job && job.hasLinkedSlot()" (click)="serviceSlotsModal.open()" class="btn-blue">
                Unlink job
            </button>

            <p *ngIf="hasToBuy()" class="mt-6 text-base text-gray-600">
                You have no available {{ service.getSlotName() || "slot" }}s. Buy more slots to unlock all the features:
            </p>

            <p *ngIf="!hasToBuy() && service && service.getIncluded() && !job.hasLinkedSlot()" class="mt-6 text-base text-gray-600">
                Link this job to a {{ service.getName() }} slot to unlock all the features:
            </p>

            <p *ngIf="service && service.getIncluded() && job.hasLinkedSlot()" class="mt-6 text-base text-gray-600">
                You have unlocked all the features for this job:
            </p>

            <ul *ngIf="service && service.getIncluded()" role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                <li *ngFor="let inc of service.getIncluded()" class="flex justify-between">
                    <div class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        {{ service.getIncludedText(inc, getElementLanguage(inc)) }}
                    </div>

                    <div *ngIf="service.getIncludedInfoText(inc, getElementLanguage(inc))">
                        <div class="group relative inline-block">
                            <i class="bi bi-info-circle"></i>
                            <div class="z-10 absolute hidden group-hover:block text-xs text-white bg-gray-600 p-2 rounded-lg mt-1" [ngClass]="service.getIncludedInfoText(inc, getElementLanguage(inc)).length < 15 ? 'w-max' : 'w-52'">
                                <p class="text-sm">
                                {{ service.getIncludedInfoText(inc, getElementLanguage(inc)) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            

            <!--
                <div class="my-3">
                Questions? <a class="link" href="https://calendly.com/fredrik-hicareer/30min" target="_blank">Book a demo</a>
                </div>
            -->

        </div>
    </div>
</div>


<app-modal #serviceModal [size]="'xl'">
    <app-service *ngIf="serviceModal.isOpen() && job" [companyId]="job.getCompanyId()" [period]="'monthly'" [slug]="'e-scout'"></app-service>
</app-modal>

<app-modal #serviceSlotsModal [size]="'lg'">
    <h2 *ngIf="service" class="text-xl font-semibold mb-6">{{ service.getName() }} Slots</h2>

    <app-slot-table *ngIf="serviceSlotsModal.isOpen() && subscription" [subscription]="subscription" [showDelete]="false" (unlinked)="unlinkSlot()"></app-slot-table>
</app-modal>
