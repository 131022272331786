<div>
    <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <!--
                            <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">ID</th>
                            -->
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" *ngIf="_subscription.getServicePer() == 'job'">Job</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" *ngIf="_subscription.getServicePer() == 'company_user'">Company User</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" *ngIf="_subscription.getServicePer() == 'company'">Company</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" *ngIf="_subscription.getServicePer() == 'user'">User</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                        <tr *ngFor="let slot of _subscription.getSlots()">
                            <!--
                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ slot.getId() }}</td>
                            -->
                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                <span *ngIf="slot.isAvailable()">Available</span>
                                <span *ngIf="!slot.isAvailable()">Used</span>
                            </td>

                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900" *ngIf="_subscription.getServicePer() == 'job'">
                                <span *ngIf="slot.getJobId()">
                                    {{ slot.getJobTitle() }}
                                    <button class="btn-outline-blue-xs ml-4" (click)="unlinkSlot(slot.getId())">Unlink</button>
                                </span>
                                <span *ngIf="!slot.getJobId()">
                                    <i>Go to a job to link it</i>
                                </span>
                            </td>

                            <td class="whitespace-nowrap px-2 py-2 text-right">
                                <button *ngIf="_subscription.getSlotCount() > 1 && showDelete" class="text-sm text-gray-500 cursor-pointer" (click)="cancelSlot(slot.getId())">Delete</button>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

