<h3 class="text-lg font-semibold mb-4"
    *ngIf="!thankYou && type != 'update' && _service && _service.getTrialPeriodDays() > 0 && canBuy() && (!subscription || (subscription && !subscription.isActive()))">
    {{ 'subscription.Try' | translate }} {{ _service.getTrialPeriodDays() }} {{ 'subscription.days for free' | translate }}
</h3>

<h3 class="text-lg font-semibold mb-4" *ngIf="!thankYou && isCardUpdateNeeded() && (subscription && !subscription.isCanceled()) && !loadingConfirmCardPaymentModal">
    Your card needs to be updated
</h3>

<!--
<h3 class="text-lg font-semibold mb-4" *ngIf="!thankYou && (subscription && subscription.isCanceled())">
    Subscription canceled
</h3>
-->

<!-- error message -->
<div class="my-5 p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" *ngIf="errorMessage">{{ errorMessage }}</div>
<div class="my-5 p-4 mb-4 text-sm text-orange-800 rounded-lg bg-yellow-50 border border-orange-200" *ngIf="!thankYou && !errorMessage && isCardUpdateNeeded() && !loadingConfirmCardPaymentModal">
    We have encountered problems with your current credit card. To resolve this, please add a new credit card.
</div>


<div *ngIf="type == 'update'">
    <h3 class="text-lg font-semibold mb-4">{{ 'subscription.udpate-your-payment-method' | translate }}</h3>
</div>

<div class="flex justify-center" *ngIf="loadingCompany || loadingService || loadingAccountUser || loadingConfirmCardPaymentModal">
    <div role="status">
        <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="thankYou" style="text-align: center;">
    <img src="assets/img/welcome-doublecloud-premium.png" style="margin: auto; margin-bottom: 50px; max-width: 250px;" alt="">
    <h2 class="text-lg font-semibold">
        {{ 'word.Success' | translate }}!
    </h2>

    <div *ngIf="_service.getPer() == 'company' || _service.getPer() == 'company_user'" class="mt-4">
        <a class="btn-success mx-auto" *ngIf="company" [routerLink]="['/company/' + company.getId() ]">View company</a>
    </div>

    <!--
        dont show to non-company users
    <div class="mt-4">
        <a class="btn-success mx-auto" *ngIf="company" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'settings' }">{{ 'subscription.Add-users' | translate }}</a>
    </div>
    -->
</div>

<div class="mt-4 space-y-3" *ngIf="!company && loggedInAccountUser && _service && (_service.getPer() == 'company' || _service.getPer() == 'company_user' || _service.getPer() == 'job')">
    <div *ngIf="loggedInAccountUser.getCompanies().length < 1">{{ 'Add a company' | translate }}</div>
    <div *ngIf="loggedInAccountUser.getCompanies().length == 1">{{ loggedInAccountUser.getCompanies()[0].name }}</div>
    <div *ngIf="loggedInAccountUser.getCompanies().length > 1">{{ 'Select a company' | translate }}</div>

    <div *ngFor="let company of loggedInAccountUser.getCompanies()">
        <button class="btn-outline-blue" (click)="_companyId = company.id; getCompany(company.id)">
            {{ company.name || "Company" }}
        </button>
    </div>
</div>

<!--company already has service-->
<div *ngIf="!thankYou && loggedInAccountUser && company && _service && loggedInAccountUser.hasAccess(_service.getKey(), company.getId()) && !_service.getUseSlots() && !isCardUpdateNeeded()">
        You already have access to this service.
        <!--
        <br />
        <br />
        <button class="btn-outline-primary" [ngClass]="{ 'selected': pp == 'services' }" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'services' }">View services</button>
        -->
</div>

<!-- native platforms not supported right now -->
<div *ngIf="isNativePlatform">
    <b>Subscriptions Coming Soon!</b>
    <p>We’re working on bringing in-app subscriptions directly to your Android and iOS devices. Currently, in-app purchases are not available. To subscribe to our service, please visit hicareer.com. We appreciate your patience and can’t wait to offer you a seamless experience directly through the app soon!</p>
</div>

<!--au already has service-->
<div *ngIf="!thankYou && loggedInAccountUser && !company && _service && loggedInAccountUser.hasAccess(_service.getKey(), null) && !_service.getUseSlots() && !isCardUpdateNeeded()">
        You already have access to this service.
        <br />
        <br />
        <button class="btn-outline-primary" [routerLink]="['/me/settings/']" [queryParams]="{ p: 'subscriptions' }">View services</button>
</div>

<form id="payment-form" [ngStyle]="{ 'display': canBuy() ? 'block' : 'none' }">

    <div class="flex items-center justify-between gap-x-6 py-5">
        <div class="min-w-0" *ngIf="selectedPaymentMethod">
            <div class="flex items-center gap-x-3">
                <p class="text-sm font-semibold leading-6 text-gray-900">{{ selectedPaymentMethod.getCardBrand() | titlecase }}</p>
                &#x2022;&#x2022;&#x2022;&#x2022;
                <p class="text-sm font-semibold leading-6 text-gray-900">{{ selectedPaymentMethod.getCardLast4() }}</p>

                <p class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20">Default</p>
            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p class="whitespace-nowrap" [ngClass]="{ 'text-red-600': selectedPaymentMethod.isCardExpired() }">
                Expires {{ selectedPaymentMethod.getCardExpMonth() }}/{{ selectedPaymentMethod.getCardExpYear() }}
                </p>
            </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
            <button class="btn-outline-blue-sm" *ngIf="paymentMethods && paymentMethods.length > 0" (click)="viewPaymentMethodsModal.open()">Edit card</button>
        </div>
    </div>

    <app-modal #viewPaymentMethodsModal [size]="'sm'">
        <h3 class="text-lg font-semibold">Select your default payment method</h3>

        <div class="my-6">
            <button class="btn-blue-sm" (click)="setNewCard()">Add new card</button>
        </div>

        <ul role="list" class="divide-y divide-gray-100">
            <li class="flex items-center justify-between gap-x-6 py-5" *ngFor="let pm of paymentMethods">
                <div class="min-w-0">
                    <div class="flex items-center gap-x-3">
                        <p class="text-sm font-semibold leading-6 text-gray-900">{{ pm.getCardBrand() | titlecase }}</p>
                        &#x2022;&#x2022;&#x2022;&#x2022;
                        <p class="text-sm font-semibold leading-6 text-gray-900">{{ pm.getCardLast4() }}</p>

                        <!--
                        <p class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20">Default</p>
                        -->

                        <button class="text-gray-600" *ngIf="paymentMethodId != pm.getId()" (click)="detachPaymentMethod(pm)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                        </button>
                    </div>
                    <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p class="whitespace-nowrap" [ngClass]="{ 'text-red-600': pm.isCardExpired() }">Expires {{ pm.getCardExpMonth() }}/{{ pm.getCardExpYear() }}</p>
                    </div>
                </div>
                <div class="flex flex-none items-center gap-x-4">
                    <button class="btn-blue-xs" [disabled]="pm.isCardExpired()" [ngClass]="{ 'btn-blue-xs': paymentMethodId == pm.getId(), 'btn-outline-blue-xs': paymentMethodId != pm.getId() }" (click)="selectPaymentMethod(pm)">
                        <span *ngIf="paymentMethodId == pm.getId() && !errorMessage">Default</span>
                        <span *ngIf="paymentMethodId == pm.getId() && errorMessage">Try again</span>
                        <span *ngIf="paymentMethodId != pm.getId()">Make default</span>
                    </button>
                </div>
            </li>
        </ul>

    </app-modal>


    <div [ngClass]="{'block': !paymentMethodId || addCardBox, 'hidden': paymentMethodId || !addCardBox }">

        <h3 class="text-md font-semibold">Add card</h3>

        <div id="card-element" #card class="card-box">
            <!-- Elements will create input elements here -->
        </div>

        <div class="flex gap-1">
            <button class="btn-blue-sm" (click)="addCard()">Save card</button>
            <button class="btn-outline-blue-sm" *ngIf="paymentMethods && paymentMethods.length > 0" (click)="cancelCard()">Cancel</button>
        </div>

    </div>

    <!--
    <div class="my-5" *ngIf="company && company.getCountryCode() != 'SE'">
        <app-country-select [countryId]="company.getCountryId()" (selected)="setCountryFromSelect($event)"></app-country-select>
    </div>
    -->

    <div class="my-5" *ngIf="!loggedInAccountUser?.getCountryCode()">
        <app-country-select [countryId]="loggedInAccountUser?.getCountryId()" (selected)="setCountryFromSelect($event)"></app-country-select>
    </div>

    <div class="my-5" *ngIf="company && !company.getVatNr() && countryCode != 'SE' && countryInEU">
        <label class="form-label">{{ 'subscription.VAT-registration-number' | translate }}</label>
        <input type="text" class="form-control" [(ngModel)]="companyVATnr" [ngModelOptions]="{standalone: true}">
    </div>


    <div class="my-5 p-4 text-sm text-gray-800 border border-gray-200 rounded-lg bg-blue-50" *ngIf="subscription && subscription.isActive() && _service.getUseSlots()">
        You already have an active
        <b *ngIf="subscription.getPeriod() == 'annually'">annual</b>
        <b *ngIf="subscription.getPeriod() == 'monthly'">monthly</b>
        subscription for this service with
        <b>{{ subscription.getQuantity() }} {{ getTranslatedSlotName() }}</b>,

        <span *ngIf="!subscription.getCancelsAt() && subscription.getRenewsAt()">scheduled to renew on {{ subscription.getRenewsAt() | date:'mediumDate' }}.</span>
        <span *ngIf="subscription.getCancelsAt()">end on {{ subscription.getCancelsAt() | date:'mediumDate' }}.</span>
        &nbsp;
        <button class="btn-link text-blue-600" (click)="viewSlotsModal.open()">View {{ subscription.getSlotName() || "slots" }}</button>
    </div>

    <div class="my-10 space-y-3" *ngIf="!isCardUpdateNeeded() && type == 'new'">

        <div class="headline">
            <div>
                Buy
                <span *ngIf="subscription && subscription.isActive() && _service.getUseSlots()">more</span>
            </div>

            <!--
            <div class="sliderPeriod">
                <div class="left">{{ 'form.monthly' | translate }}</div>
                <input type="range" min="0" max="1" step="1" value="1" (change)="setPeriod($event)" class="slider">
                <div class="right">{{ 'form.annually' | translate }}</div>
            </div>
            -->
        </div>

        <div class="grid grid-cols-2 w-full text-sm gap-1 items-center justify-between" *ngIf="_service">
            <div>
                {{ _service.getName() }}
            </div>

            <div class="flex justify-end" *ngIf="_service.getUseSlots()">
                <div class="h-10 w-36 flex items-center border border-gray-200 rounded">
                    <button type="button" (click)="decreaseQuantity()" class="flex-none w-10 h-10 leading-10 text-gray-600 transition hover:opacity-75 cursor-pointer">
                        &minus;
                    </button>

                    <input (change)="updateQuantity($event)"
                            type="number"
                            value="{{ _service.getQuantity() }}"
                            class="flex-fill h-10 w-16 border-gray-300 text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
                            />

                    <button type="button" (click)="increaseQuantity()" class="flex-none w-10 h-10 leading-10 text-gray-600 transition hover:opacity-75 cursor-pointer">
                        &plus;
                    </button>
                </div>
            </div>

            <div *ngIf="!_service.getUseSlots()" class="text-right">
                <!-- net -->
                {{ getNet(_service) | number }} {{ _service.getCurrencyCode() }}
            </div>
        </div>
        <div class="grid grid-cols-2 w-full text-sm gap-1" *ngIf="(_service.getQuantity() ?? 0) > 1 || _service.getUseSlots()">
            <div>
                <!-- price & quantity -->
                {{ getSumQuantity() }}
                <span *ngIf="_service.getUseSlots()">{{ getTranslatedSlotName() }}</span>
                <span *ngIf="!_service.getUseSlots()">
                    <span *ngIf="_service.getPer() == 'company_user'">{{ 'word.users' | translate }}</span>
                    <span *ngIf="_service.getPer() != 'company_user'">{{ 'word.qty' | translate }}</span>
                </span>
                x
                {{ _service.getPrice() | number }}
                {{ _service.getCurrencySymbol() }}
            </div>
            <div *ngIf="_service" class="text-right">
                {{ (getSumQuantity() ?? 0) * _service.getPrice() | number }} {{ _service.getCurrencyCode() }}
            </div>
        </div>
        <div class="grid grid-cols-2 w-full text-sm gap-1" *ngIf="!_service.isVatIncluded() && tax > 0">
            <div>
                {{ 'subscription.VAT' | translate }} {{ tax * 100 }}%
            </div>
            <div class="text-right">
                <!-- swedish tax -->
                {{ getTax(_service) | number }} {{ _service.getCurrencyCode() }}
            </div>
        </div>

        <hr />

        <div class="grid grid-cols-2 w-full text-sm gap-1">
            <div>
                {{ 'word.total' | translate }}
                <span *ngIf="_service.getPeriod() == 'monthly'">{{ 'word.per-month' | translate }}</span>
                <span *ngIf="_service.getPeriod() == 'annually'">{{ 'word.per-year' | translate }}</span>
            </div>
            <div class="text-right">
                <span>{{ getTotal(_service) | number }} {{ _service.getCurrencyCode() }}</span>
            </div>
        </div>

    </div>

    <!--
    <p style="margin-top: 20px; font-size: 14px;" *ngIf="type == 'new'">You will not be charged anything during the free trial. Billing will automatically start after the trial period has ended. You can cancel the subscription at any time.</p>
    -->
    <div class="my-3 text-sm space-y-4" *ngIf="!isCardUpdateNeeded() && type == 'new'">

        <div class="flex gap-1" *ngIf="subscription && subscription.getPeriod() == 'monthly' && _service.getPeriod() == 'annually' && subscription && subscription.isActive()">
            <svg class="h-6 w-5 flex-none text-green-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <div>
                You have selected to upgrade your existing subscription <b>from monthly to annual billing</b>.
                <span *ngIf="_service.getUseSlots()">This will affect all the {{ _service.getSlotName() || "slot" }}s and take effect immediately.</span>
                <b>Your credit card will be charged {{ getTotal(_service) | number }} {{ _service.getCurrencyCode() }} minus unused time on your monthly subscription so far.</b>
            </div>
        </div>
        <div class="flex gap-1" *ngIf="subscription && subscription.isActive() && subscription.getCancelsAt()">
            <svg class="h-6 w-5 flex-none text-green-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <div>
                Your subscription is set to expire on {{ subscription.getCancelsAt() | date:'mediumDate' }}.
                However, if you purchase additional {{ _service.getSlotName() || "slot" }}s, the cancellation will be overridden,
                and the service will instead <b>renew on {{ subscription.getCancelsAt() | date:'mediumDate' }}</b>.
            </div>
        </div>
        <div class="flex gap-1" *ngIf="subscription && subscription.isActive() && _service.getUseSlots() && (subscription.getQuantity() ?? 0) < (subscription.getQuantity() ?? 0) + (_service.getQuantity() ?? 0)">
            <svg class="h-6 w-5 flex-none text-green-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <div *ngIf="_service && subscription">
                The total number of <span *ngIf="_service.getUseSlots()">{{ _service.getSlotName() || "slot" }}<span *ngIf="(subscription.getQuantity() ?? 0) + (_service.getQuantity() ?? 0) > 1">s</span></span>
                will be increased from {{ subscription.getQuantity() }} to {{ subscription.getQuantity() + (_service.getQuantity() ?? 0) }} on your existing subscription.
            </div>
        </div>
        <div class="flex gap-1" *ngIf="_service.getTrialPeriodDays() > 0 && (!subscription || (subscription && !subscription.isActive()))">
            <svg class="h-6 w-5 flex-none text-green-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <div>
                <div class="font-semibold">
                    {{ 'subscription.Try' | translate }} {{ _service.getTrialPeriodDays() }}
                    {{ 'subscription.days free trial' | translate }}
                </div>
                {{ 'During the trial period, your card will not be charged, and you have the freedom to cancel at any time.' | translate }}
            </div>
        </div>
        <div class="flex gap-1">
            <svg class="h-6 w-5 flex-none text-green-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <div *ngIf="_service.getIterations() == 1">
                {{ 'subscription.Cancel-your-subscription-at-any-time' | translate }}
            </div>
            <div *ngIf="_service.getIterations() > 1">
                {{ 'This service has a' | translate }} {{ _service.getIterations() }}{{ '-month subscription term that automatically renews every' | translate }} {{ _service.getIterations() }} {{ 'months unless cancelled.' | translate }}
                <span *ngIf="_service.getTrialPeriodDays() > 0 && (!subscription || (subscription && !subscription.isActive()))">
                    {{ 'You can cancel at any time during the trial period, and you will not be charged.' | translate }}
                </span>
            </div>
        </div>
        <div class="flex gap-1" *ngIf="company && !subscription">
            <svg class="h-6 w-5 flex-none text-green-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <div>
                {{ 'word.Upgrade' | translate }} {{ company.getName() }} {{ 'word.with' | translate }} {{ _service.getName() }}
            </div>
        </div>

    </div>

    <div class="mt-6 mb-3 text-xs" *ngIf="!isCardUpdateNeeded() && _service.getTerms()">
        By proceeding, you agree to the <span class="link" (click)="viewTermsModal.open()">{{ _service.getName() }} Terms of Service</span>
    </div>

    <div class="mt-4 flex gap-1">

        <!--
            if the user is updating their card, we don't need to show the button
            because the button is only for new subscriptions
        -->
        <button *ngIf="!isCardUpdateNeeded() && !addCardBox" type="submit" class="btn-primary" (click)="subscribe()" [disabled]="loading">
            <span *ngIf="type == 'new' && _service.getTrialPeriodDays() == 0">Buy now</span>
            <!--
                <span *ngIf="type == 'update'">{{ 'subscription.Update-card' | translate }}</span>
            -->
            <span *ngIf="type == 'new' && _service.getTrialPeriodDays() > 0">
                <span *ngIf="!subscription">{{ 'subscription.Start' | translate }} {{ _service.getTrialPeriodDays() }} {{ 'subscription.days free trial' | translate }}</span>
                <span *ngIf="subscription">Buy now</span>
            </span>
        </button>

        <button *ngIf="isCardUpdateNeeded() && !addCardBox && !errorMessage && !loadingConfirmCardPaymentModal" type="submit" class="btn-primary" (click)="setDefaultCard()" [disabled]="loading">
            Try again
        </button>

        <span class="ml-3" role="status" *ngIf="loading">
            <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
        </span>

    </div>

</form>


<app-modal #viewSlotsModal [size]="'lg'">
    <h2 class="text-xl font-semibold mb-6">{{ _service.getName() }}</h2>

    <div>

        <dl class="grid grid-cols-2 gap-x-8 gap-y-12 sm:grid-cols-3" *ngIf="_service && subscription">
            <div class="flex flex-col-reverse border-l border-white/20 pl-6">
                <dt class="text-sm leading-7 text-gray-600">Total {{ _service.getSlotName() || "slot" }}s</dt>
                <dd class="text-lg font-semibold tracking-tight">{{ subscription.getSlotCount() }}</dd>
            </div>
            <div class="flex flex-col-reverse border-l border-white/20 pl-6">
                <dt class="text-sm leading-7 text-gray-600">Used {{ _service.getSlotName() || "slot" }}s</dt>
                <dd class="text-lg font-semibold tracking-tight">{{ subscription.getSlotUsedCount() }}</dd>
            </div>

            <div class="flex flex-col-reverse border-l border-white/20 pl-6">
                <dt class="text-sm leading-7 text-gray-600">Available {{ _service.getSlotName() || "slot" }}s</dt>
                <dd class="text-lg font-semibold tracking-tight">{{ subscription.getSlotAvailableCount() }}</dd>

            </div>
        </dl>

    </div>

    <app-slot-table *ngIf="viewSlotsModal.isOpen()" [subscription]="subscription"></app-slot-table>
</app-modal>

<app-modal #viewTermsModal [size]="'lg'">
    <h2 class="text-xl font-semibold mb-6">{{ _service.getName() }} Terms of Service</h2>

    <pre class="mt-4 text-sm text-gray-600" [innerHtml]="_service.getTerms()"></pre>
</app-modal>

