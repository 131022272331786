import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

//import { LanguageService } from '../_services/language.service';

import { Service, Subscription, Invoice, PaymentMethod } from '../_models/service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    card: any;

    lang: string | null = 'en';

    constructor(
        private http: HttpClient,
        // TODO this leads to circular dependency
        //private languageService: LanguageService,
    ) {
        //this.lang = this.languageService.getLanguage();
    }

    // we can only create one card so it has to be a service
    /*getCard(elements: any) {
        if (this.card) {
            this.card.unmount();
        } else {
            this.card = elements.create('card');
        }
        return this.card;
    }*/

    // premium service
    premiumService: any = null;

    getPremiumService(): any {
        return this.premiumService;
    }

    setPremiumService(service: any): void {
        this.premiumService = service;
    }

    checkPremium(companyId: number, accountUserId: number): Observable<any> {
        let url = this.domain + this.prefix + '/service/check-premium/';
        return this.http.post(url, { 'company-id': companyId, 'au-id': accountUserId });
    }

    // service

    getService(slug: string, period: string, currency: string, companyId?: number, auId?: number): Observable<Service> {
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/service/' + slug;

        url = url + '?period=' + period + '&currency=' + currency + `&timestamp=${timestamp}`;

        if (companyId)
            url = url + '&company=' + companyId;

        if (auId)
            url = url + '&au=' + auId;

        if (this.lang)
            url = url + '&lang=' + this.lang;

        return this.http.get<Service>(url).pipe(
            map(s => new Service().deserialize(s))
        );
    }

    getServiceBySubscriptionId(id: number): Observable<Service> {
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/service/by_subscription_id/';

        url = url + '?sub-id=' + id + `&timestamp=${timestamp}`;

        return this.http.get<Service>(url).pipe(
            map(s => new Service().deserialize(s))
        );
    }

    getServices(tier?: string): Observable<Service[]> {
        let url = this.domain + this.prefix + '/service/';

        if (tier)
            url = url + '?tier=' + tier;

        if (this.lang) {
            url += tier ? '&lang=' + this.lang : '?lang=' + this.lang;
        }

        return this.http.get<Service[]>(url).pipe(
            map(objs => objs
                .map(obj => new Service().deserialize(obj))
            )
        );
    }

    getSubscriptions(companyId: number, accountUserId: number): Observable<Subscription[]> {
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/service-subscription/';
        url = url + '?company-id=' + companyId + '&au-id=' + accountUserId + `&timestamp=${timestamp}`;
        return this.http.get<Subscription[]>(url).pipe(
            map(objs => objs
                .map(obj => new Subscription().deserialize(obj))
            )
        );
    }

    getActiveSubscriptions(accountUserId: number): Observable<Subscription[]> {
        let url = this.domain + this.prefix + '/service-subscription/active_per_user/';
        url = url + '?au-id='+accountUserId;
        return this.http.get<Subscription[]>(url).pipe(
            map(objs => objs
                .map(obj => new Subscription().deserialize(obj))
            )
        );
    }

    getSubscription(id: number): Observable<Subscription> {
        let url = this.domain + this.prefix + '/service-subscription/' + id;
        return this.http.get<Subscription>(url).pipe(
            map(s => new Subscription().deserialize(s))
        );
    }

    linkSlotToJob(subscriptionId: number, jobId: number): Observable<any> {
        let url = this.domain + this.prefix + '/service-subscription/link_slot/';
        return this.http.post<any>(url, {'subscription-id': subscriptionId, 'job-id': jobId});
    }

    unlinkSlot(subscriptionId: number, slotId: number): Observable<any> {
        let url = this.domain + this.prefix + '/service-subscription/unlink_slot/';
        return this.http.post<any>(url, {'subscription-id': subscriptionId, 'slot-id': slotId});
    }

    cancelSlot(subscriptionId: number, slotId: number): Observable<any> {
        let url = this.domain + this.prefix + '/service-subscription/cancel_slot/';
        return this.http.post<any>(url, {'subscription-id': subscriptionId, 'slot-id': slotId});
    }

    getInvoices(customerId: string): Observable<Invoice[]> {
        let url = this.domain + this.prefix + '/service-invoice/';
        url = url + '?customer-id='+customerId;
        return this.http.get<Invoice[]>(url).pipe(
            map(objs => objs
                .map(obj => new Invoice().deserialize(obj))
            )
        );
    }

    createStripeCustomer(email, companyId, auId): Observable<any> {
        let url = this.domain + this.prefix + '/service/pay/create-customer/';
        if (companyId)
            return this.http.post(url, {'email': email, 'company_id': companyId});
        else
            return this.http.post(url, {'email': email, 'au_id': auId});
    }

    getOrCreateStripeCustomer(email, companyId, auId, countryCode): Observable<any> {
        let url = this.domain + this.prefix + '/service/pay/get-or-create-customer/';
        if (companyId)
            return this.http.post(url, {'email': email, 'company_id': companyId, 'country_code': countryCode});
        else
            return this.http.post(url, {'email': email, 'au_id': auId, 'country_code': countryCode});
    }

    createStripeSubscription(data): Observable<any> {
        let url = this.domain + this.prefix + '/service/pay/create-subscription/';
        return this.http.post<any>(url, data);
    }

    updateStripeSubscription(data): Observable<any> {
        let url = this.domain + this.prefix + '/service/pay/update-subscription/';
        return this.http.post(url, data);
    }

    setDefaultStripeCard(data): Observable<any> {
        let url = this.domain + this.prefix + '/service/pay/set-default-card/';
        return this.http.post(url, data);
    }

    cancelSubscription(id): Observable<any> {
        let url = this.domain + this.prefix + '/service/pay/cancel-subscription/';
        return this.http.post(url, {'id': id});
    }

    retryInvoice(data): Observable<any> {
        let url = this.domain + this.prefix + '/service/pay/retry-invoice/';
        return this.http.post(url, data);
    }

    getLoggedInAccountUserStripePaymentMethods(): Observable<PaymentMethod[]> {
        const timestamp = Date.now();

        let url = this.domain + this.prefix + '/account-user/list_stripe_payment_methods/';

        url = url + `?timestamp=${timestamp}`;

        return this.http.get<PaymentMethod[]>(url).pipe(
            map(objs => objs
                .map(obj => new PaymentMethod().deserialize(obj))
            )
        );
    }

    getLoggedInAccountUserDefaultStripePaymentMethod(): Observable<PaymentMethod> {
        const timestamp = Date.now();

        let url = this.domain + this.prefix + '/account-user/default_stripe_payment_method/';

        url = url + `?timestamp=${timestamp}`;

        return this.http.get<PaymentMethod>(url).pipe(
            map(s => new PaymentMethod().deserialize(s))
        );
    }

    detachPaymentMethod(id): Observable<any> {
        let url = this.domain + this.prefix + '/account-user/detach_stripe_payment_method/';
        return this.http.post(url, {'id': id});
    }

    attachPaymentMethodToCustomer(pmId: string, customerId: string): Observable<any> {
        let url = this.domain + this.prefix + '/service/pay/attach-pm-to-customer/';
        return this.http.post(url, {'pm_id': pmId, 'customer_id': customerId});
    }


}




