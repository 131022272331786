import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { AccountUser } from '../../_models/user';
import { Job } from '../../_models/company';
import { Service, Subscription } from '../../_models/service';

import { AlertService } from '../../_services/alert.service';
import { SubscriptionService } from '../../_services/subscription.service'

@Component({
  selector: 'app-slot-setup',
  templateUrl: './slot-setup.component.html',
  styleUrls: ['./slot-setup.component.scss']
})
export class SlotSetupComponent implements OnInit {

    @ViewChild('serviceModal') serviceModal;
    @ViewChild('serviceSlotsModal') serviceSlotsModal;

    @Input() set setJob(job: Job) {
        this.job = job;

        if (this.job && this.job.hasLinkedSlot()) {
            this.expanded = false;
        } else {
            this.expanded = true;
        }

        this.getService();
    }

    @Input() accountUser: AccountUser | undefined | null;
    @Input() serviceKey: string;

    @Output() jobLinked = new EventEmitter<boolean>();
    @Output() jobUnlinked = new EventEmitter<boolean>();

    job: Job;
    service: Service;

    expanded: boolean = false;

    loading: boolean = false;

    subscription: Subscription;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private alertService: AlertService,
        private subscriptionService: SubscriptionService,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        this.getService();
    }

    getService() {
        this.loading = true;

        if (this.job && this.accountUser) {
            this.subscriptionService.getService(this.serviceKey, '', this.job.getCurrencyCode(), this.job.getCompanyId()).subscribe(
                s => {
                    this.service = s;
                    if (s.getActiveSubscription()) {
                        this.subscription = new Subscription().deserialize(s.getActiveSubscription());
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                }
            );
        }

    }

    toggleExpanded() {
        this.expanded = !this.expanded;
    }

    hasToBuy(): boolean {
        if (this.subscription && this.subscription.getSlotAvailableCount() == 0 && !this.job.hasLinkedSlot()) {
            return true;
        }
        return false;
    }

    hasToLink(): boolean {
        if (this.subscription && this.subscription.getSlotAvailableCount() > 0 && !this.job.hasLinkedSlot()) {
            return true;
        }
        return false;
    }

    linkSlot(subscription: Subscription) {
        if (subscription && this.job) {
            this.subscriptionService.linkSlotToJob(subscription.id, this.job.getId()).subscribe(
                res => {
                    this.jobLinked.emit(true);
                    this.getService();
                    this.alertService.success('The job has been linked to an available slot');
                },
                err => {
                    console.log(err);
                }
            );
        } else {
            console.log('No subscription or job to link to');
        }
    }

    unlinkSlot() {
        this.jobUnlinked.emit(true);
        this.getService();
        this.alertService.warning('The job has been unlinked from the role');
    }

    getElementLanguage(element: any) {
        if (!element)
            return undefined;

        if (isPlatformBrowser(this.platformId)) {
            let localStorageLang = localStorage.getItem('currentLang');
            const browserLang = this.translate.getBrowserLang();

            if (localStorageLang && element.hasOwnProperty(localStorageLang)) {
                return localStorageLang;

            } else if (browserLang && element.hasOwnProperty(browserLang)) {
                return browserLang;

            } else if (element.en) {
                return 'en';

            } else {
                return undefined;
            }
        }
        return undefined;
    }


}
